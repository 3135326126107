<template>
   <div class="container_24">
    <div class="wrapper">
      <div class="grid_24 footer-bg">
        <div class="hr-border-2"></div>
        <div class="wrapper">
          <div class="grid_7 suffix_1 prefix_1 alpha">
            <div class="copyright">
              &copy; 2024 <strong class="footer-logo">AgroPlus</strong>
              <div>
                Website Template by
                <a target="_blank" href="#"
                  > Fomo122@gmail.com</a
                >
              </div>
            </div>
          </div>
          <div class="grid_4">
            <h5 class="heading-1">Date:</h5>
            <ul class="footer-list">
              <li><a href="#">5TH March 2024</a></li>
            </ul>
          </div>
          <div class="grid_4">
            <h5 class="heading-1">School:</h5>
            <ul class="footer-list">
              <li><a href="#">The ICT University</a></li>
            </ul>
          </div>
          <div class="grid_4">
            <h5 class="heading-1">Address:</h5>
            <ul class="footer-list">
              <li><a href="#">Yaounde, Cameroon</a></li>
            </ul>
          </div>
          <div class="grid_2 suffix_1 omega">
            <ul class="social-list">
             
              
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        responseData: [],
        file: null
      };
    },
    methods: {
   
        
    }
  };
  </script>
  
  <style>
  /* Your CSS styles here */
  </style>
  


